<template>
    <!-- <div style="height: auto; max-height: 244px"> -->
    <div class="row justify-center font-20 font-medium">Lịch sử chuyến đi</div>
    <DxList
        :dataSource="{
            store: DanhSachLichSuChuyenDi,
            paginate: false,
        }"
        height="288px"
        :onItemClick="ChonChuyenDi"
        item-template="list-item"
    >
        <template #list-item="{ data, index }">
            <div
                :style="
                    guidChuyenDi == data.guidChuyenDi ? 'color: #03a9f4' : ''
                "
                class="font-14 row align-center"
                :title="`${data.maChuyenDi}`"
            >
                <div
                    v-if="index % 2 == 0"
                    class="color-success mr-2"
                    :style="
                        guidChuyenDi == data.guidChuyenDi
                            ? 'color: #03a9f4 !important'
                            : ''
                    "
                >
                    <i class="mdi mdi-bus-side"></i>
                    <!-- <i class="mdi mdi-chevron-right"></i> -->
                </div>
                <div
                    v-else
                    class="color-warning mr-2"
                    style="transform: rotateY(180deg)"
                    :style="
                        guidChuyenDi == data.guidChuyenDi
                            ? 'color: #03a9f4 !important'
                            : ''
                    "
                >
                    <i class="mdi mdi-bus-side"></i>
                    <!-- <i class="mdi mdi-chevron-right"></i> -->
                </div>
                {{ `${data.maChuyenDi} | ${GioXuatBen(data.gioXuatBen)}` }}
            </div>
        </template>
    </DxList>

    <!-- <div style="margin-top: 48px">
            <div
                class="row align-center no-wrap font-14"
                v-for="(item, index) in DanhSachLichSuChuyenDi"
                :key="index"
                :class="
                    index + 1 == DanhSachLichSuChuyenDi.length ? '' : 'pb-2'
                "
                :style="
                    guidChuyenDi == item.guidChuyenDi ? 'color: #03a9f4' : ''
                "
                @click="ChonChuyenDi(item)"
                :title="`${item.maChuyenDi}: ${item.tenBenDi} - ${item.tenBenDen}`"
            ><i
                    v-if="index % 2 == 0"
                    class="mdi mdi-bus-side mr-2 color-success"
                    :style="
                        guidChuyenDi == item.guidChuyenDi
                            ? 'color: #03a9f4 !important'
                            : ''
                    "
                ></i>
                <i
                    class="mdi mdi-bus-side mr-2 color-warning"
                    :style="
                        guidChuyenDi == item.guidChuyenDi
                            ? 'color: #03a9f4 !important'
                            : ''
                    "
                    style="transform: rotateY(180deg)"
                    v-else
                ></i>
                {{ `${item.maChuyenDi}` }}</div>
        </div>
    </div> -->
</template>

<script>
import { DxList } from "devextreme-vue";
export default {
    components: {
        DxList,
    },
    props: {
        DanhSachLichSuChuyenDi: { type: Array, default: () => [] },
        guidChuyenDi: { type: String, default: null },
    },
    methods: {
        ChonChuyenDi(item) {
            this.$emit("ChonLichSuChuyenDi", item.itemData.guidChuyenDi);
        },
        GioXuatBen(date) {
            if (date) {
                let tmpDate = new Date(date);
                let hours =
                    tmpDate.getHours() < 10
                        ? "0" + tmpDate.getHours()
                        : tmpDate.getHours();
                let minutes =
                    tmpDate.getMinutes() < 10
                        ? "0" + tmpDate.getMinutes()
                        : tmpDate.getMinutes();
                let tmpStr = hours + ":" + minutes;
                return tmpStr;
            }
            return "";
        },
    },
};
</script>

<style scoped>
.no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>