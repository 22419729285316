<template>
    <ion-page>
        <ThanhTieuDeVue>
            <template #before>
                <div
                    class="row align-center pl-2"
                    v-if="chuyenDiGanDay && chuyenDiGanDay.maChuyenDi"
                >
                    <i class="mdi mdi-magnify mr-1"></i>
                    <DxTextBox
                        v-model="txtTimKiem"
                        mode="number"
                        styling-mode="underlined"
                        placeholder="Nhập số điện thoại..."
                        :elementAttr="{ class: 'text-box-noline' }"
                        valueChangeEvent="keyup"
                    />
                    <!-- :onKeyUp="getDanhSachHangHoaTheoChuyenDi" -->
                </div>
            </template>
            <template #after>
                <!-- <DxButton
                    type="default"
                    icon="mdi mdi-qrcode-scan"
                    class="custom-icon-QR"
                    width="34px"
                    height="34px"
                    @click="quetMa()"
                    v-if="chuyenDiGanDay && chuyenDiGanDay.maChuyenDi"
                /> -->
                <DxButton
                    :active-state-enabled="false"
                    :focus-state-enabled="false"
                    :hover-state-enabled="false"
                    :text="SoLuongHangTrenXe + ''"
                    type="normal"
                    styling-mode="text"
                    width="24px"
                    height="24px"
                    class="nut-chuthich-style clear-rounder text-xs-center mr-1 ml-2"
                    :style="`background-color: #fff;border-radius:4px; color: #000`"
                    @click="
                        getDanhSachTrangThaiHang();
                        dialogChuThich = true;
                    "
                    v-if="chuyenDiGanDay && chuyenDiGanDay.maChuyenDi"
                />
            </template>
        </ThanhTieuDeVue>

        <ion-content :scroll-events="false">
            <div
                v-if="!DaDangKySuDungVe"
                class="row pa-2 pt-3 justify-center align-center text-xs-center font-16 font-medium"
                style="height: calc(100vh - 48px - 36px); position: relative"
            >
                Bạn chưa đăng ký sử dụng dịch vụ Vé điện tử. Vui lòng liên hệ Công ty của
                bạn để được cập nhật!
            </div>
            <div
                class="row pa-2 pt-3 justify-center align-center text-xs-center font-16 font-medium"
                style="height: calc(100vh - 48px - 36px); position: relative"
                v-else-if="!chuyenDiGanDay || !chuyenDiGanDay.maChuyenDi"
            >
                Không tìm thấy thông tin chuyến đi!
            </div>
            <div
                style="height: calc(100vh - 36px - 48px)"
                class="row justify-center"
                v-else
            >
                <!-- Không có dữ liệu -->
                <div class="xs12" style="position: relative">
                    <div class="row pt-2 px-3 mb-2 align-center">
                        <div class="grow">
                            <div
                                class="row align-center"
                                @click="
                                    getDanhSachLichSuChuyenDi();
                                    dialogChonLichSuChuyenDi = true;
                                "
                            >
                                {{ ThongTinThemCuaChuyenDi }}
                                <i class="mdi mdi-chevron-down ml-1"></i>
                            </div>
                        </div>

                        <div class="shrink">
                            Tổng:
                            <span class="font-14 font-medium">
                                {{ $MoneyFormat(TongTien) }}đ
                            </span>
                        </div>
                    </div>
                    <div
                        style="
                            padding: 0 16px 4px;
                            width: 100%;
                            height: calc(100vh - 144px);
                            overflow-y: scroll;
                        "
                    >
                        <DanhSachHangVue
                            :DanhSachHangHoa="DanhSachHangHoa"
                            @TraHang="TraHang"
                        />
                        <!-- @thongBaoLoi="thongBaoLoi"
                            @toastThanhCong="toastThanhCong"
                            @layDanhSachKhachMuaVeKhongCoSoDoCho="
                                layDanhSachKhachMuaVeKhongCoSoDoCho
                            " -->
                    </div>

                    <!-- <div class="row justify-center mt-3">
                        <DxButton
                            text="In vé"
                            type="default"
                            styling-mode="outlined"
                            icon="mdi mdi-printer"
                            width="144px"
                        />
                    </div> -->

                    <div class="fab-float-button row justify-center align-center">
                        <DxButton
                            v-if="
                                guidChuyenDi &&
                                chuyenDiGanDay &&
                                guidChuyenDi == chuyenDiGanDay.guidChuyenDi
                            "
                            text="Lấy hàng"
                            type="normal"
                            styling-mode="text"
                            icon="mdi mdi-cart-arrow-down"
                            @click="LayHangHoa()"
                        />
                    </div>
                </div>
            </div>
        </ion-content>

        <!-- ====================================Popup chọn chuyến đi ========================================= -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogChonLichSuChuyenDi"
            title=""
            :fullWidth="false"
            :useButtonLeft="false"
            :useButtonRight="false"
            :column="false"
            heightScrollView="auto"
        >
            <template #content>
                <PopupChonLichSuChuyenDiVue
                    :DanhSachLichSuChuyenDi="DanhSachLichSuChuyenDi"
                    :guidChuyenDi="guidChuyenDi"
                    @ChonLichSuChuyenDi="ChonLichSuChuyenDi"
                />
            </template>
        </PopupVue>

        <!-- ====================================Popup chú thích ========================================= -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogChuThich"
            title=""
            :fullWidth="false"
            :useButtonLeft="false"
            :useButtonRight="false"
            :column="false"
            classPopup="popup-no-padding"
        >
            <template #content>
                <PopupTrangThaiHangVue :DanhSachTrangThaiHang="DanhSachTrangThaiHang" />
            </template>
        </PopupVue>

        <!-- ====================================Popup Trả hàng ========================================= -->
        <PopupVue
            v-model:dialog="dialogTraHang"
            title=""
            :fullWidth="false"
            :useButtonLeft="false"
            :useButtonRight="false"
            :column="false"
            classPopup="popup-padding-bottom-0"
        >
            <template #content>
                <TraHangVue
                    :ThongTinTraHang="ThongTinTraHang"
                    :dialogTraHang="dialogTraHang"
                    @TraHangThanhCong="TraHangThanhCong"
                />
            </template>
        </PopupVue>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonPage,
    IonToolbar,
    IonHeader,
    onIonViewWillEnter,
    onIonViewWillLeave,
} from "@ionic/vue";
import { DxButton, DxTextBox } from "devextreme-vue";
import DanhSachHangVue from "./components/DanhSachHang.vue";
import ThanhTieuDeVue from "../../components/_Common/ThanhTieuDe.vue";
import PopupVue from "../../components/_Common/Popup.vue";
import ChuyenDi from "../../../class/ChuyenDi";
import { mapActions, mapGetters } from "vuex";
import PopupChonLichSuChuyenDiVue from "../../components/Popup/PopupChonLichSuChuyenDi.vue";
import TraHangVue from "./components/TraHang.vue";
import PopupTrangThaiHangVue from "./components/PopupTrangThaiHang.vue";
export default {
    components: {
        IonContent,
        IonPage,
        IonToolbar,
        IonHeader,
        DxButton,
        DxTextBox,
        ThanhTieuDeVue,
        DanhSachHangVue,
        ThanhTieuDeVue,
        PopupChonLichSuChuyenDiVue,
        PopupVue,
        PopupTrangThaiHangVue,
        TraHangVue,
    },
    data() {
        return {
            dialogChuThich: false,
            dialogTraHang: false,
            txtTimKiem: "",
            guidChuyenDi: "",
            DanhSachLichSuChuyenDi: [],
            DanhSachTrangThaiHang: [],
            ThongTinThemCuaChuyenDi: "",
            dialogChonLichSuChuyenDi: false,
            DanhSachHangHoa: [],
            SoLuongHangTrenXe: 0,
            ThongTinTraHang: {},
            DaDangKySuDungVe: false,
        };
    },
    computed: {
        ...mapGetters("Ve", ["chuyenDiGanDay"]),
        TongTien() {
            let total = 0;
            if (this.DanhSachHangHoa.length > 0) {
                this.DanhSachHangHoa.forEach((e) => {
                    total += e.donGia;
                });
            }
            return total;
        },
    },
    watch: {
        txtTimKiem(value) {
            setTimeout(() => {
                if (value == this.txtTimKiem) {
                    this.getDanhSachHangHoaTheoChuyenDi();
                }
            }, 1100);
        },
    },
    methods: {
        ...mapActions("Ve", ["layChuyenDiGanDay", "LayThongTinThemCuaChuyenDi"]),
        ...mapActions("HangHoa", [
            "LayDanhSachHangHoaTheoChuyenDi",
            "LayDanhSachTrangThaiHang",
        ]),
        async getDanhSachLichSuChuyenDi() {
            //Lấy ds trạng thái chỗ
            let DanhSachLichSuChuyenDi = await new ChuyenDi().layDanhSachLichSuChuyenDi(
                this.chuyenDiGanDay.guidChuyenDi,
            );
            this.DanhSachLichSuChuyenDi = DanhSachLichSuChuyenDi || [];
        },
        async getDanhSachHangHoaTheoChuyenDi(LayHang) {
            let toast = {
                type: "error",
                visible: true,
            };
            this.DanhSachHangHoa = [];
            try {
                let query = {
                    idChuyenDi: this.guidChuyenDi,
                    timKiem: this.txtTimKiem || null,
                };
                this.$startLoading;
                let rs = await this.LayDanhSachHangHoaTheoChuyenDi(query);
                // this.$stopLoading
                if (LayHang) {
                    this.getDanhSachTrangThaiHang();
                }
                if (rs.Data.status == true) {
                    this.DanhSachHangHoa = rs.Data.data;
                } else if (
                    rs.Data.status == false &&
                    rs.Data.errorCode != this.$i18n.t("MaLoi.KhongTimThayDuLieu")
                ) {
                    toast.message = rs.Data.message;
                    this.emitter.emit("onToast", toast);
                }
            } catch (error) {
                if (error.StatusMessage) {
                    toast.message = error.StatusMessage;
                } else {
                    toast.message = error;
                }
                this.emitter.emit("onToast", toast);
            } finally {
                this.$stopLoading;
            }
        },
        async getThongTinThemChuyenDi() {
            let space = `\xa0\xa0\xa0\xa0\xa0\xa0`;
            try {
                let rs = await this.LayThongTinThemCuaChuyenDi({
                    GuidChuyenDi: this.guidChuyenDi,
                });
                if (!rs.Data.status) {
                    this.toast = {
                        message: rs.Data.message,
                        type: "error",
                        visible: true,
                    };
                } else {
                    let data = rs.Data.data;

                    this.ThongTinThemCuaChuyenDi = data.maChuyenDi;
                }
            } catch (error) {
                let toast = {
                    message: error,
                    type: "error",
                    visible: true,
                };
                this.emitter.emit("onToast", toast);
            }
        },
        LayHangHoa() {
            let query = { ...this.chuyenDiGanDay };
            this.$router.push({
                path: "/Nhan-Hang-Hoa",
                query: query,
            });
        },
        async ChonLichSuChuyenDi(guidChuyenDi) {
            this.dialogChonLichSuChuyenDi = false;
            this.guidChuyenDi = guidChuyenDi;
            this.getDanhSachHangHoaTheoChuyenDi(true);
            this.getThongTinThemChuyenDi();
        },
        async getDanhSachTrangThaiHang() {
            let toast = {
                type: "error",
                visible: true,
            };
            this.DanhSachTrangThaiHang = [];
            //Lấy ds trạng thái chỗ
            try {
                let rs = await this.LayDanhSachTrangThaiHang(this.guidChuyenDi);
                if (rs.Data.status == true) {
                    this.SoLuongHangTrenXe = 0;
                    this.DanhSachTrangThaiHang = rs.Data.data || [];
                    this.DanhSachTrangThaiHang.forEach((e) => {
                        this.SoLuongHangTrenXe += e.soLuong;
                    });
                } else {
                    toast.message = "Lấy danh sách trạng thái hàng thất bại!";
                    this.emitter.emit("onToast", toast);
                }
            } catch (error) {
                toast.message = error;
                this.emitter.emit("onToast", toast);
            }
        },
        TraHang(item) {
            this.ThongTinTraHang = item;
            this.dialogTraHang = true;
        },
        TraHangThanhCong() {
            this.getDanhSachHangHoaTheoChuyenDi(true);
            this.dialogTraHang = false;
        },
        getData() {
            if (this.chuyenDiGanDay && this.chuyenDiGanDay.maChuyenDi) {
                this.guidChuyenDi = this.chuyenDiGanDay.guidChuyenDi;
                this.getDanhSachHangHoaTheoChuyenDi(true);
                this.getThongTinThemChuyenDi();
            } else {
                let toast = {
                    message: "Không tìm thấy chuyến đi!",
                    type: "success",
                    visible: true,
                };
                this.emitter.emit("onToast", toast);
            }
        },
        closeAllDialog() {
            this.dialogChuThich = false;
            this.dialogTraHang = false;
            this.dialogChonLichSuChuyenDi = false;
        },
    },

    created() {
        onIonViewWillEnter(async () => {
            this.DaDangKySuDungVe = false;
            localStorage.removeItem("ChoDaChon");
            let SuDungDichVu = this.$Core.AuthApi.TokenParsed.SuDungDichVu || null;
            if (SuDungDichVu) {
                let arrDichVu = SuDungDichVu.split(";");
                arrDichVu.forEach(async (e) => {
                    if (e == this.$t("StringCheck.SuDungVe")) {
                        this.DaDangKySuDungVe = true;
                        await this.layChuyenDiGanDay();
                        this.getData();
                    }
                });
            } else {
                await this.layChuyenDiGanDay();
                this.getData();
            }
        });
    },
    mounted() {
        onIonViewWillLeave(() => {
            this.closeAllDialog();
        });
    },
};
</script>

<style scoped>
>>> .custom-toolbar .toolbar-container {
    height: 48px;
}
>>> .text-box-noline.dx-texteditor.dx-editor-underlined::after {
    border-bottom: unset;
}
>>> .text-box-noline .dx-texteditor-input,
>>> .text-box-noline .dx-placeholder {
    color: #fff;
    font-size: 12px;
}
>>> .custom-icon-QR i.dx-icon {
    font-size: 20px;
}
>>> .nut-chuthich-style.dx-button-has-text {
    min-width: unset !important;
}
>>> .nut-chuthich-style .dx-button-content {
    padding: 0 !important;
}
>>> .nut-chuthich-style. span {
    color: rgb(0, 0, 0, 0.87);
}
>>> .fab-float-button .dx-button {
    border-radius: 50px;
}
</style>

<style lang="scss" scoped>
.fab-float-button {
    box-shadow: $box_shadow;
    border-radius: 50px;
    width: max-content;
    // height: 32px;
    position: fixed;
    right: 16px;
    bottom: 8px;
    z-index: 10;
    background-color: white;
}
.fab-float-button:active {
    opacity: 0.7;
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .fab-float-button {
        bottom: 48px;
    }
}
</style>
